import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import useHandleErrors from '../../../../hooks/useHandleErrors';
import api from '../../../../services/api';
import { useAuth } from '../../../../contexts/AuthContext';

export default function RemoveACcount() {
	const { enqueueSnackbar } = useSnackbar();

	const navigation = useNavigate();
	const [errs, setError] = useState();
	useHandleErrors(errs);
	const { LogOut } = useAuth();

	const { handleSubmit, isSubmitting } = useFormik({
		initialValues: {},
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true);
				await api.delete('customer');
				LogOut();
                enqueueSnackbar('Conta removida' );
				setSubmitting(false);
			} catch (error) {
				setError(error);
			}
		},
	});

	return (
		<LoadingButton
			onClick={e=>window.confirm('Remover conta permanentemente? Você não terá mais acesso aos dados.') && handleSubmit() }
			fullWidth
			disabled={isSubmitting}
			loading={isSubmitting}
			variant="text">
			Remover conta
		</LoadingButton>
	);
}
