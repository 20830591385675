import { Card, CardActions, CardContent, Typography, TextField, Switch, Stack, FormGroup, FormControlLabel } from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';

import api from '../services/api';
import useHandleErrors from '../hooks/useHandleErrors';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { validaCPF } from '../services/validDoc';

export function ProtectedLogin() {
	const validateLogin = Yup.object().shape({
		document: Yup.string()
			.length(11, 'CPF incompleto')
			.test('invalid-cpf', 'CPF inválido', cpf => validaCPF(cpf))
			.required('Campo obrigatório'),
		password: Yup.string().min(6, 'Mínimo de 6 caracteres').required('Campo obrigatório'),
	});

	const navigation = useNavigate();
	const [error, setError] = useState();
	const [checked, setChecked] = React.useState();
	useHandleErrors(error);

	const { verifySession } = useAuth();

	const { handleChange, handleSubmit, values, errors, touched, isSubmitting, setValues, setFieldValue } = useFormik({
		initialValues: {
			document: '',
			password: '',
		},
		validationSchema: validateLogin,
		onSubmit: async values => {
			try {
				if (checked) {
					window.localStorage.setItem('User', JSON.stringify(values));
				}
				const { data } = await api.post('auth/login', values);
				sessionStorage.setItem('token', data.token);
				sessionStorage.setItem('user', JSON.stringify(data.user));
				verifySession();
				if (data.user.team == 'adm') {
					navigation('/backoffice');
				}
				if (data.user.team == 'user') {
					navigation('/user/rescue-points');
				}
				if (data.user.team == 'cus') {
					navigation('/dashboard');
				}
			} catch (error) {
				setError(error);
			}
		},
	});
	useEffect(() => {
		if (window.localStorage.getItem('User')) {
			const user = JSON.parse(window.localStorage.getItem('User'));
			if (user) setChecked(true);
			setValues({ document: user.document, password: user.password });
		} else if (!checked && window.localStorage.getItem('User')) {
			window.localStorage.removeItem('User');
		}
	}, []);
	function rememberMe(remember) {
		setChecked(remember);
		if (!remember) {
			window.localStorage.removeItem('User');
		}
	}
	return (
		<Stack sx={{ justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
			<Card
				elevation={3}
				sx={{
					borderRadius: '6px',
					width: '22rem',
					maxWidth: '23rem',
					height: '30rem',
					maxHeight: '32rem',
					padding: '0.7rem',
				}}>
				<CardContent>
					<Typography sx={{ fontSize: '0.9rem', fontWeight: '700' }} color="#A0AEC0">
						Faça seu login novamente
					</Typography>

					<Stack direction="column" spacing={3} sx={{ mt: 4 }}>
						<TextField
							label="CPF"
							variant="outlined"
							id="document"
							name="document"
							type="document"
							onChange={handleChange}
							value={values.document}
							error={errors.document}
							helperText={errors.document}
						/>

						<TextField
							label="Senha"
							variant="outlined"
							id="password"
							name="password"
							type="password"
							onChange={handleChange}
							value={values.password}
							error={errors.password}
							helperText={errors.password}
						/>
					</Stack>

					<FormGroup>
						<FormControlLabel
							control={<Switch onChange={e => rememberMe(e.target.checked)} checked={checked} />}
							label="Lembrar acesso"
						/>
					</FormGroup>
				</CardContent>
				<CardActions>
					<LoadingButton
						onClick={handleSubmit}
						fullWidth
						sx={{ color: '#FAFAFA' }}
						disabled={isSubmitting}
						loading={isSubmitting}
						variant="contained">
						Entrar
					</LoadingButton>
				</CardActions>
			</Card>
		</Stack>
	);
}
