import { Paper, Button, Typography, Stack, ButtonGroup } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFetch';
import Filter from './components/Filter';
import CleanFilter from '@mui/icons-material/Delete';
import CustomersList from './components/CustomersList';
import PageHead from '../../../components/PageHead';
import { useSnackbar } from 'notistack';
import api from '../../../services/api';
export default () => {
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [filter, setFilter] = useState(false);
	const [params, setParams] = useState({});
	const [filtered, setfiltered] = useState('none');

	const { enqueueSnackbar } = useSnackbar();

	const { data, error, mutate, isFetching } = useFetch('/customers', {
		params: {
			limit: pageSize,
			paged: true,
			page: page,
			...params,
		},
	});
	
	const handleParams = e => {
		setParams(e);
		setPage(0);
	};

	const [isDownloading, setDownloading] = useState();
	const downloadExcel = async () => {
		try {
			setDownloading(true);
			const { data } = await api.get(`/relatorio`, {
				headers: {
					'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				},
				responseType: 'blob',
				params: params,
			});
			const link = document.createElement('a');
			const url = window.URL.createObjectURL(new Blob([data]));
			link.href = url;
			link.setAttribute('download', `Report_${new Date().toLocaleDateString()}.xlsx`);
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
			setDownloading();
		} catch (error) {
			setDownloading();
			enqueueSnackbar('Erro. Revise os dados do cadastro', { variant: 'portal' });
		}
	};

	return (
		<Paper elevation={3} sx={{ borderRadius: '6px', padding: '2rem' }}>
			<Filter filter={filter} setFilter={setFilter} setParams={handleParams} setFiltered={setfiltered} />
			<PageHead pageTitle="Lista de Clientes">
				<ButtonGroup variant="contained" aria-label="outlined primary button group">
					<Button onClick={() => downloadExcel()}>Baixar lista</Button>
					<Button onClick={() => setFilter(!filter)}>Filtros</Button>
					{Boolean(Object.keys(params).length) && (
						<Button
							onClick={() => {
								handleParams({});
							}}>
							<CleanFilter />
						</Button>
					)}
				</ButtonGroup>
			</PageHead>
			<CustomersList
				data={data}
				page={page}
				setPage={setPage}
				pageSize={pageSize}
				setPageSize={setPageSize}
				isFetching={isFetching}
			/>
		</Paper>
	);
};
