import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useAuth } from './AuthContext';

const AppContext = createContext({});
export const AppProvider = ({ children }) => {
	const [question, setQuestion] = useState(false);
	const [orderStatus, setOrderStatus] = useState('');

	return (
		<AppContext.Provider
			value={{
				question,
				setQuestion,
				orderStatus,
				setOrderStatus,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export const useApp = () => {
	const context = useContext(AppContext);
	return context;
};
