import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

const useHandleErrors = errors => {
	const { verifySession } = useAuth();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (errors) {
			switch (errors.status) {
				case 401:
					sessionStorage.removeItem('token');
					verifySession();

				case 400:
					if (errors.response) {
						if (Array.isArray(errors.response.data.message)) {
							errors.response.data.message.forEach(item => {
								enqueueSnackbar(item.message);
							});
						} else {
							enqueueSnackbar(errors.response.data.message);
						}
					} else {
						enqueueSnackbar(errors.message);
					}

				case 200:
					enqueueSnackbar(errors.response.data.message);

				default:
					if (errors.response) {
						if (Boolean(errors.response?.data?.message)) {
							enqueueSnackbar(errors.response.data.message);
						} else {
							enqueueSnackbar(errors.message);
						}
					} else {
						enqueueSnackbar(errors.message);
					}
			}
		}
	}, [errors]);
};

export default useHandleErrors;
