import { useState } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import AddQuestion from './components/AddQuestion';
import FilterQuestions from './components/FilterQuestions';
import QuestionList from './components/QuestionList';

export default () => {

	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [filter, setFilter] = useState(false);
	const [params, setParams] = useState({});

	const {  data, error, mutate, isFetching } = useFetch(`/questions`, {
		params: {
			limit: pageSize,
			page: page,
			paged: true,
			...params,
		},
	});

	const handleParams = e => {
		setParams(e);
		setPage(0);
	};

	return (
		<>
			<FilterQuestions filter={filter} setFilter={setFilter} setParams={handleParams} />

			<AddQuestion
				mutate={mutate}
				setFilter={setFilter}
				filter={filter}
				setParams={handleParams}
				params={params}
			/>

			<QuestionList
				data={data}
				isFetching={isFetching}
				pageSize={pageSize}
				page={page}
				setPage={setPage}
				setPageSize={setPageSize}
			/>
		</>
	);
};
