import axios from 'axios';

export const baseURL = process.env.REACT_APP_SERVER;

const api = axios.create({
	baseURL: `${baseURL}/api/v1`,
});

api.interceptors.response.use(
	response => response,
	error => {
		throw error;
	},
);

export default api;
