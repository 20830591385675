import { useParams } from 'react-router-dom';
import { useFetch } from '../../../../hooks/useFetch';
import { UsersForm } from '../components/UsersForm';
import LoadingContainer, { NothingContent } from '../../../../components/LoadingContent';

export default () => {
	const { id } = useParams();

	const { data, error, mutate, isFetching } = useFetch(`/users/${id}`);

	if (isFetching) return <LoadingContainer />;
	if (!Boolean(data)) return <NothingContent label="Nenhum dado encontrado" />;

	return <UsersForm data={data} />;
};
