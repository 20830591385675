import { IconButton, SwipeableDrawer, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

export default function GlobalFilter({ handleClose, open, title, width, children }) {
	return (
		<Box
			component="nav"
			aria-label="filter-rigth"
			sx={{ flexGrow: 1, width: { sm: '95%', md: width || '400px' }, flexShrink: { sm: 0 } }}
		>
			<SwipeableDrawer
				anchor={'right'}
				open={open}
				onClose={handleClose}
				onOpen={() => {}}
				sx={{ width: { xs: '100%', md: width || '400px' } }}
			>
				<Toolbar sx={{ justifyContent: 'space-between' }}>
					<Typography variant="h3">{title || ''}</Typography>
					<IconButton onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</Toolbar>

				<Box p={3} sx={{ width: { xs: '95%', md: width || '400px' } }}>
					{children}
				</Box>
			</SwipeableDrawer>
		</Box>
	);
}
