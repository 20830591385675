import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import Nothing from '@mui/icons-material/DoNotDisturb';

export default function LoadingContainer() {
	return (
		<Box sx={{ mt: '8rem', mb: '8rem', textAlign: 'center' }}>
			<CircularProgress color="success" />
		</Box>
	);
}

export function NothingContent({ label }) {
	return (
		<Box sx={{ mt: '8rem', mb: '8rem', textAlign: 'center' }}>
			<Nothing color="disabled" sx={{ fontSize: 60 }} />
			{label && <Typography color="disabled">{label}</Typography>}
		</Box>
	);
}

export function ErroContent({ label }) {
	return (
		<Box sx={{ mt: '8rem', mb: '8rem', textAlign: 'center' }}>
			<Nothing color="disabled" sx={{ fontSize: 60 }} />
			{label && <Typography color="disabled">{label}</Typography>}
		</Box>
	);
}
