import { Outlet } from 'react-router-dom';
import { NoAuthorized } from '../../components/ProtectedContainer';
import { useAuth } from '../../contexts/AuthContext';
import StoreContainer from './StoreContainer';

export default function ProtectedStore() {
	const { user } = useAuth();

	if (user.team === 'user') {
		return (
			<StoreContainer>
				<Outlet />
			</StoreContainer>
		);
	} else {
		return <NoAuthorized />;
	}
}
