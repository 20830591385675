import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Checkbox, FormControlLabel, ButtonGroup, Switch } from '@mui/material';
import Paper from '@mui/material/Paper';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useHandleErrors from '../../../../hooks/useHandleErrors';
import { useFormik } from 'formik';
import api from '../../../../services/api';
import { useApp } from '../../../../contexts/AppContext';
import { useSnackbar } from 'notistack';
import CleanFilter from '@mui/icons-material/Delete';
import PageHead from '../../../../components/PageHead';

export default ({ mutate, store_id, setFilter, filter, filtered, params, setFiltered, setParams }) => {
	const { question, setQuestion } = useApp();
	const { enqueueSnackbar } = useSnackbar();
	const [buttonName, setButtonName] = useState('Criar');
	const ContainerOrdersScheema = Yup.object().shape({
		statement: Yup.string().required('Campo obrigatório'),
	});

	const [error, setError] = useState();

	const navigation = useNavigate();

	useHandleErrors(error);

	const { handleChange, handleSubmit, handleReset, values, errors, isSubmitting, setFieldValue, setValues } = useFormik({
		initialValues: {
			statement: '',
			status: true,
		},
		validationSchema: ContainerOrdersScheema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true);

				console.log(values);
				if (values.id) {
					await api.put(`/questions/${values.id}`, values);
					enqueueSnackbar('Pergunta alterada', { variant: 'success' });
					setQuestion();
				} else {
					const response = await api.post('/questions', values);

					enqueueSnackbar('Pergunta adicionada', { variant: 'success' });
				}

				handleReset();
				setSubmitting(false);
				mutate();
			} catch (error) {
				setError(error);
			}
		},
	});
	useEffect(() => {
		if (question) {
			setValues({ ...question }, false);
		}
	}, [question]);
	return (
		<>
			<Paper elevation={3} sx={{ borderRadius: '6px', padding: '2rem', mb: '2rem' }}>
				<PageHead pageTitle="Perguntas para avaliação">
					<ButtonGroup variant="contained" aria-label="outlined primary button group">
						<Button onClick={() => setFilter(!filter)}>Filtros</Button>
						{Boolean(Object.keys(params).length) && (
							<Button
								onClick={() => {
									setParams({});
									setFiltered('none');
								}}>
								<CleanFilter />
							</Button>
						)}
					</ButtonGroup>
				</PageHead>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name="statement"
							value={values.statement}
							onChange={handleChange}
							error={Boolean(errors.statement)}
							helperText={errors.statement}
							fullWidth
							label="Enunciado da pergunta"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							label={values.status? 'Disponível': 'Indisponível'}
							control={
								<Switch
									onChange={e => setFieldValue('status', e.target.checked)}
									checked={values.status}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<LoadingButton onClick={() => handleSubmit()} loading={isSubmitting} type="submit" variant="contained">
							{values.id ? 'Salvar alteração' : 'Criar nova pergunta'}
						</LoadingButton>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
};
