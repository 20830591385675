import { Button } from '@mui/material';
import Card from './Card';
import { Trophy } from 'phosphor-react';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import api from '../../../../services/api';
import { useSnackbar } from 'notistack';
import useHandleErrors from '../../../../hooks/useHandleErrors';
import { useState } from 'react';
export default ({ result, mutate }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [error, setError] = useState();
	useHandleErrors(error);

	const { handleSubmit, handleReset, isSubmitting, setFieldValue, setValues } = useFormik({
		initialValues: {},
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true);
				const response = await api.put(`/score/${result.user.document}`);
				enqueueSnackbar('Pontos resgatados', { variant: 'success' });
				setSubmitting(false);
                mutate()
			} catch (error) {
				handleReset();
				setError(error);
			}
		},
	});

	return (
		<>
			<Card icon={<Trophy size={80} />} name="Total de Pontos" result={result} />
			<LoadingButton
				loading={isSubmitting}
				variant="contained"
				onClick={e => window.confirm('Deseja resgatar os pontos do cliente?') && handleSubmit()}
				fullWidth
				disabled={!Boolean(result.count)}>
				Resgatar pontos
			</LoadingButton>
		</>
	);
};
