import { Card, CardActions, CardContent, Typography, TextField, Stack, IconButton, Paper, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { validaCPF } from '../../../services/validDoc';
import axios from 'axios';
import Search from '@mui/icons-material/TravelExplore';
import { useFetch } from '../../../hooks/useFetch';
import { useEffect } from 'react';
import useHandleErrors from '../../../hooks/useHandleErrors';
import api from '../../../services/api';
import PageHead from '../../../components/PageHead';

export function ChangePass() {
	const validateLogin = Yup.object().shape({
		password: Yup.string().min(6, 'Mínimo de 6 caracteres').required('Campo obrigatório'),
		repeat_password: Yup.string()
			.oneOf([Yup.ref('password'), null], 'As senhas não conferem')
			.required('Campo obrigatório'),
	});

	const { enqueueSnackbar } = useSnackbar();

	const navigation = useNavigate();
	const [errs, setError] = useState();

	useHandleErrors(errs);

	const { handleChange, handleSubmit, handleReset, values, errors, isSubmitting, setValues, setFieldValue, setFieldError } =
		useFormik({
			initialValues: {
				password: '',
				repeat_password: '',
			},
			validationSchema: validateLogin,
			onSubmit: async (values, { setSubmitting, resetForm }) => {
				try {
					setSubmitting(true);
					await api.put('profile', values);
					enqueueSnackbar('Senha alterada', { variant: 'success' });
					handleReset();
					setSubmitting(false);
				} catch (error) {
					setError(error);
				}
			},
		});

	return (
		<Paper
			elevation={3}
			sx={{
				borderRadius: '6px',
				padding: 2,
				mb: 6,
			}}>
			<PageHead pageTitle="Mudar senha" />

			<Grid container spacing={3}>
				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						label="Senha"
						variant="outlined"
						id="password"
						name="password"
						type="password"
						onChange={handleChange}
						value={values.password}
						error={errors.password}
						helperText={errors.password}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						label="Confirme a senha"
						variant="outlined"
						id="repeat_password"
						name="repeat_password"
						type="password"
						onChange={handleChange}
						value={values.repeat_password}
						error={errors.repeat_password}
						helperText={errors.repeat_password}
					/>
				</Grid>
				<Grid item xs={12} md={12}>
					<LoadingButton
						onClick={handleSubmit}
						fullWidth
						sx={{ color: '#FAFAFA' }}
						disabled={isSubmitting || Boolean(Object.keys(errors).length) || !values.password || !values.repeat_password}
						loading={isSubmitting}
						variant="contained">
						Entrar  
					</LoadingButton>
				</Grid>
			</Grid>

			{/* <FormGroup>
						<FormControlLabel
							control={<Switch onChange={e => rememberMe(e.target.checked)} checked={checked} />}
							label="Lembrar acesso"
						/>
					</FormGroup> */}
		</Paper>
	);
}
