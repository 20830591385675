import { Box, Chip, Divider, Paper, Stack, Typography } from '@mui/material';
import PageHead from '../../../components/PageHead';
import { useFetch } from '../../../hooks/useFetch';
import Verified from '@mui/icons-material/Verified';
import HighlightOff from '@mui/icons-material/HighlightOff';
import LoadingContainer, { NothingContent } from '../../../components/LoadingContent';
import SyncIcon from '@mui/icons-material/Sync';
import HourglassTop from '@mui/icons-material/HourglassTop';
import isToday from 'date-fns/isToday';
import isPast from 'date-fns/isPast';
import parseISO from 'date-fns/parseISO';
import isFuture from 'date-fns/isFuture';

export default props => {
	const { data, error, isFetching, mutate } = useFetch('/history');
	if (isFetching) return <LoadingContainer />;
	return (
		<Paper elevation={3} sx={{ borderRadius: '6px', padding: '2rem' }}>
			<PageHead pageTitle="Histórico de pontuação" />
			<Stack spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
				{!Boolean(data.length) && <NothingContent label="Nenhuma pontuação realizada" />}

				{data?.map(item => (
					<Box key={item.id}>
						<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
							<Box>
								<Typography>Dia {new Date(item.createdAt).toLocaleDateString()}</Typography>
								<Typography>Hora {new Date(item.createdAt).toLocaleTimeString()}</Typography>
								{isToday(parseISO(item.expired_at)) && <Chip color='warning' label="Expira hoje" />}
							</Box>
							<Box>
								{Boolean(item.rescued_at) && <Verified color="green" />}
								{!Boolean(item.rescued_at) && isPast(parseISO(item.expired_at)) && <HighlightOff color="red" />}
								{/* {!Boolean(item.rescued_at) && isFuture(parseISO(item.expired_at)) && <HourglassTop color="red" />} */}
								{!Boolean(item.rescued_at) && !isPast(parseISO(item.expired_at)) && <SyncIcon color="red" />}
							</Box>
						</Stack>
					</Box>
				))}
			</Stack>
		</Paper>
	);
};
