import { Card, CardActions, CardContent, Typography, TextField, Stack, IconButton, Paper, Grid, Button } from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { validaCPF } from '../../../services/validDoc';
import axios from 'axios';
import Search from '@mui/icons-material/TravelExplore';
import { useFetch } from '../../../hooks/useFetch';
import { useEffect } from 'react';
import useHandleErrors from '../../../hooks/useHandleErrors';
import api from '../../../services/api';
import PageHead from '../../../components/PageHead';
import RemoveACcount from './components/RemoveAccount';

export function Profile() {
	const validateLogin = Yup.object().shape({
		document: Yup.string()
			.length(11, 'CPF incompleto')
			.test('invalid-cpf', 'CPF inválido', cpf => validaCPF(cpf))
			.required('Campo obrigatório'),
		name: Yup.string().required('Campo obrigatório'),
		email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
		birthday: Yup.date().required('Campo obrigatório'),
		street: Yup.string().required('Campo obrigatório'),
		complement: Yup.string().required('Campo obrigatório'),
		number: Yup.string().required('Campo obrigatório'),
		neighborhood: Yup.string().required('Campo obrigatório'),
		state: Yup.string().required('Campo obrigatório'),
		city: Yup.string().required('Campo obrigatório'),
	});

	const { data, error, isFetching, mutate } = useFetch('/profile');

	const { enqueueSnackbar } = useSnackbar();

	const navigation = useNavigate();
	const [errs, setError] = useState();

	useHandleErrors(errs);

	const { handleChange, handleSubmit, handleReset, values, errors, isSubmitting, setValues, setFieldValue, setFieldError } =
		useFormik({
			initialValues: {
				document: '',
				name: '',
				email: '',
				zipcode: '',
				street: '',
				complement: '',
				neighborhood: '',
				state: '',
				city: '',
				number: '',
				birthday: '',
			},
			validationSchema: validateLogin,
			onSubmit: async (values, { setSubmitting, resetForm }) => {
				try {
					setSubmitting(true);
					await api.put('profile', values);
					enqueueSnackbar('Cadastro alterado', { variant: 'success' });
					setSubmitting(false);
				} catch (error) {
					setError(error);
				}
			},
		});

	const [searchingZipcode, setSearchZipcode] = useState(false);
	const getAddress = async zipcode => {
		try {
			setFieldValue('zipcode', zipcode);

			if (zipcode.length === 8) {
				setSearchZipcode(true);
				const { data } = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);
				setValues({
					...values,
					street: data.logradouro,
					neighborhood: data.bairro,
					city: data.localidade,
					state: data.uf,
					zipcode,
				});
				setSearchZipcode(false);
			}
		} catch (error) {
			setFieldError('zipcode', 'CEP não encontrado');
			console.log('Error', error);
		}
	};

	useEffect(() => {
		if (data) {
			const { Customer, ...user } = data;
			setValues({ ...Customer, ...user }, false);
		}
	}, [data]);

	return (
		<Paper
			elevation={3}
			sx={{
				borderRadius: '6px',
				padding: 2,
				mb: 6,
			}}>
			<PageHead pageTitle="Editar dados">
				{' '}
				<Button onClick={() => navigation('/change-pass')}>Mudar senha</Button>
			</PageHead>

			<Grid container spacing={3}>
				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						label="CPF"
						variant="outlined"
						id="document"
						name="document"
						onChange={handleChange}
						value={values.document}
						error={errors.document}
						helperText={errors.document}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						label="Nome completo"
						variant="outlined"
						id="name"
						name="name"
						onChange={handleChange}
						value={values.name}
						error={errors.name}
						helperText={errors.name}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						label="Data de nascimento"
						variant="outlined"
						id="birthday"
						name="birthday"
						type="date"
						onChange={handleChange}
						value={values.birthday}
						error={errors.birthday}
						helperText={errors.birthday}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						label="E-mail"
						variant="outlined"
						id="email"
						name="email"
						type="email"
						onChange={handleChange}
						value={values.email}
						error={errors.email}
						helperText={errors.email}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						label="CEP"
						variant="outlined"
						id="zipcode"
						name="zipcode"
						onChange={e => getAddress(e.target.value)}
						value={values.zipcode}
						error={errors.zipcode}
						helperText={errors.zipcode}
						// InputProps={{
						// 	endAdornment: <InputAdornment position="end">
						// 		<IconButton >

						// 		</IconButton>
						// 	</InputAdornment>,
						//   }}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						label="Rua"
						variant="outlined"
						id="street"
						name="street"
						onChange={handleChange}
						value={values.street}
						error={errors.street}
						helperText={errors.street}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						label="Complemento"
						variant="outlined"
						id="complement"
						name="complement"
						onChange={handleChange}
						value={values.complement}
						error={errors.complement}
						helperText={errors.complement}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						label="Numero"
						variant="outlined"
						id="number"
						name="number"
						onChange={handleChange}
						value={values.number}
						error={errors.number}
						helperText={errors.number}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						label="Bairro"
						variant="outlined"
						id="neighborhood"
						name="neighborhood"
						onChange={handleChange}
						value={values.neighborhood}
						error={errors.neighborhood}
						helperText={errors.neighborhood}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						label="UF"
						variant="outlined"
						id="state"
						name="state"
						onChange={handleChange}
						value={values.state}
						error={errors.state}
						helperText={errors.state}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						label="Cidade"
						variant="outlined"
						id="city"
						name="city"
						onChange={handleChange}
						value={values.city}
						error={errors.city}
						helperText={errors.city}
					/>
				</Grid>

				<Grid item xs={12} md={12}>
					<LoadingButton
						onClick={handleSubmit}
						fullWidth
						sx={{ color: '#FAFAFA' }}
						disabled={
							isSubmitting ||
							Boolean(Object.keys(errors).length) ||
							!values.name ||
							!values.email ||
							!values.document ||
							!values.street ||
							!values.city ||
							!values.neighborhood ||
							!values.state ||
							!values.number ||
							!values.complement ||
							!values.zipcode
						}
						loading={isSubmitting}
						variant="contained">
						Salvar alterações
					</LoadingButton>
				</Grid>
			</Grid>

			<RemoveACcount />
		</Paper>
	);
}
