import { Routes, Route } from 'react-router-dom';
import ProtectedContainer from '../components/ProtectedContainer';
import { Login } from '../modules/Auth/screens';
import { LoginCustomer } from '../modules/Auth/screens/LoginCustomer';
import { RegisterCustomer } from '../modules/Auth/screens/RegisterCustomer';
import Customers from '../modules/Backoffice/Customers';
import BackOffice from '../modules/Backoffice/Home';
import ProtectedBackoffice from '../modules/Backoffice/ProtectedBackoffice';
import Questions from '../modules/Backoffice/Questions';
import Settings from '../modules/Backoffice/Settings';
import Users from '../modules/Backoffice/Users';
import UsersEdit from '../modules/Backoffice/Users/screens/UsersEdit';
import UsersList from '../modules/Backoffice/Users/screens/UsersList';
import UsersNew from '../modules/Backoffice/Users/screens/UsersNew';
import ProtectedDashboard from '../modules/Dashboard/ProtectedDashboard';
import AddPoints from '../modules/Store/AddPoints';
import RescuePoints from '../modules/Store/RescuePoints';
import HomeDashBoard from '../modules/Dashboard/Home';
import ProtectedStore from '../modules/Store/ProtectedStore';
import Rating from '../modules/Rating';
import Rescuded from '../modules/Dashboard/Rescuded';
import History from '../modules/Dashboard/History';
import { Profile } from '../modules/Dashboard/Profile';
import { ChangePass } from '../modules/Dashboard/Profile/ChangePass';
import Ratings from '../modules/Backoffice/Ratings';

export default () => {
	return (
		<Routes>
			<Route index path="/admin" element={<Login />} />
			<Route path="/" element={<LoginCustomer />} />
			<Route path="/register" element={<RegisterCustomer />} />
			<Route path="/rating" element={<Rating />} />

			<Route element={<ProtectedContainer />}>
				<Route element={<ProtectedBackoffice />}>
					<Route path="backoffice" element={<BackOffice />} />
					<Route path="customers" element={<Customers />} />
					<Route path="questions" element={<Questions />} />
					<Route path="ratings" element={<Ratings />} />
					<Route path="settings" element={<Settings />} />
					<Route path="users" element={<Users />}>
						<Route index element={<UsersList />} />
						<Route path=":id" element={<UsersEdit />} />
						<Route path="new" element={<UsersNew />} />
					</Route>
					<Route path="add-points" element={<AddPoints />} />
					<Route path="rescue-points" element={<RescuePoints />} />
				</Route>
				<Route element={<ProtectedStore />}>
					<Route path="user/rescue-points" element={<RescuePoints />} />
					<Route path="user/add-points" element={<AddPoints />} />
				</Route>
				<Route element={<ProtectedDashboard />}>
					<Route path="dashboard" element={<HomeDashBoard />} />
					<Route path="profile" element={<Profile />} />
					<Route path="rescuded" element={<Rescuded />} />
					<Route path="history" element={<History />} />
					<Route path="change-pass" element={<ChangePass />} />
				</Route>
			</Route>
		</Routes>
	);
};
