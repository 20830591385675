import { Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { NoAuthorized } from '../../components/ProtectedContainer';
import { useAuth } from '../../contexts/AuthContext';
import BackofficeContainer from './BackofficeContainer';

export default function ProtectedBackoffice() {
	const { user } = useAuth();

	if (user.team === 'adm') {
		return (
			<BackofficeContainer>
				<Outlet />
			</BackofficeContainer>
		);
	} else {
		return <NoAuthorized />
	}
}
