import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

export function Cards({ name, image, price }) {
	return (
		<Card elevation={3} sx={{ borderRadius: '8px', display: 'flex', justifyContent: 'center', flexGrow: 4 }}>
			<CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<Card
					sx={{
						boxShadow: 'none',
						borderRadius: '12px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						mb: '1.2rem',
						mt: '1rem',
					}}
				>
					<img src={image} alt="" />
				</Card>
				<Box sx={{justifyContent: 'center', alignItems: 'center', width:60, height:60, borderRadius: 50, backgroundColor: '#eee', display: 'flex', mb:2}}><Typography sx={{ color: 'black', fontWeight: '700', fontSize: 30 }}>{price}</Typography></Box>
				<Typography sx={{ fontSize: '1.12rem', fontWeight: '700', color: 'black' }}>{name}</Typography>

				{/* <Typography sx={{ fontSize: '0.80rem', fontWeight: '700', color: 'gray', mb: '0.7rem' }}>Total</Typography> */}
				
			</CardContent>
		</Card>
	);
}
