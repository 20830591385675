import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Paper, Rating, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoadingContainer, { NothingContent } from '../../components/LoadingContent';
import { useFetch } from '../../hooks/useFetch';
import useHandleErrors from '../../hooks/useHandleErrors';
import api from '../../services/api';
import VerifiedUser from '@mui/icons-material/VerifiedUser';

function useQuery() {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
}

function Rated({ label }) {
	const navigation = useNavigate();
	return (
		<Stack width="100%" height="100%" alignItems="center" justifyContent="center" >
			<Box maxWidth={300} sx={{ mt: '10rem', mb: '10rem', textAlign: 'center' }}>
				<VerifiedUser color="disabled" sx={{ fontSize: 60 }} />
				<Typography textAlign="center" color="disabled">
					{label}
				</Typography>
				<Button variant="contained" sx={{ mt: 3 }} onClick={e => navigation('/login')}>
					Fazer login
				</Button>
			</Box>
		</Stack>
	);
}

export default () => {
	const query = useQuery();
	const { data, error, mutate, isFetching } = useFetch('rating', { params: { token: query.get('token') } });
	const [errs, setErrors] = useState();
	useHandleErrors(errs);

	const { values, isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			rating_value: '',
			message: '',
		},
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true);
				await api.post('rating', values, {
					params: {
						token: query.get('token'),
					},
				});
				mutate();
				setSubmitting(false);
			} catch (error) {
				setErrors(error);
			}
		},
	});

	if (isFetching) return <LoadingContainer label="Carregando conteúdo" />;
	if (!data) return <Rated label="Avaliação realizada. Obrigado por contribuir com nosso futuro." />;

	return (
		<Stack sx={{ justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', padding: 4 }}>
			<Paper sx={{ width: { xs: '100%', md: 500 }, padding: 3 }}>
				<Typography align="center" fontSize={18}>
					{data.statement}
				</Typography>
				<Stack alignItems="center" py={4}>
					<Rating
						value={values.rating_value}
						size="large"
						sx={{ fontSize: 40 }}
						name="rating_value"
						onChange={handleChange}
					/>
				</Stack>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextField
							onChange={handleChange}
							value={values.message}
							label="Deixe uma mensagem"
							multiline
							fullWidth
							name="message"
						/>
					</Grid>
					<Grid item xs={12}>
						<LoadingButton
							onClick={handleSubmit}
							disabled={!values.rating_value || isSubmitting}
							fullWidth
							variant="contained">
							Enviar avaliação
						</LoadingButton>
					</Grid>
				</Grid>
			</Paper>
		</Stack>
	);
};
