import React, { useState } from 'react';
import { TextField, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import useHandleErrors from '../../../../hooks/useHandleErrors';
import { useFormik } from 'formik';
import api from '../../../../services/api';
import { useSnackbar } from 'notistack';
import PageHead from '../../../../components/PageHead';
import RescuePoints from './RescuePoints';

export default () => {
	const { enqueueSnackbar } = useSnackbar();

	const ContainerOrdersScheema = Yup.object().shape({
		document: Yup.string().required('Campo obrigatório'),
	});

	const [error, setError] = useState();
	useHandleErrors(error);

	const [result, setResult] = useState();

	const { handleChange, handleSubmit, handleReset, values, errors, isSubmitting, setFieldValue, setValues } = useFormik({
		initialValues: {
			document: '',
		},
		validationSchema: ContainerOrdersScheema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true);
				const response = await api.get(`/score/${values.document}`);
				setResult(response.data.data);
				enqueueSnackbar('Usuario encontrado', { variant: 'success' });

				setSubmitting(false);
			} catch (error) {
				setResult();
				handleReset();
				setError(error);
			}
		},
	});

	return (
		<>
			<Paper elevation={3} sx={{ borderRadius: '6px', padding: '2rem', mb: '2rem' }}>
				<PageHead pageTitle="Verificar pontos do cliente" />

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name="document"
							value={values.document}
							onChange={handleChange}
							error={Boolean(errors.document)}
							helperText={errors.document}
							fullWidth
							label="CPF"
							variant="outlined"
							maxLength={11}
						/>
					</Grid>

					<Grid item xs={12} md={12}>
						<LoadingButton onClick={() => handleSubmit()} loading={isSubmitting} disabled={!values.document || isSubmitting} type="submit" variant="contained">
							Verificar
						</LoadingButton>
					</Grid>
					<Grid item xs={12} sx={{ mt: 3 }}>
						{result && <RescuePoints result={result} mutate={handleSubmit} />}
					</Grid>
				</Grid>
			</Paper>
		</>
	);
};
