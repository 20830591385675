import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { SnackbarProvider } from 'notistack';
import Routes from './routes';
import { AppProvider } from './contexts/AppContext';

export function App() {
	const theme = createTheme({
		root:{
			width:'100%',
			height:'100%'
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536,
			},
		},
		palette: {
			primary: {
				main: '#B41827',
			},
			secondary: {
				main: '#FAFAFA',
			},
			black: {
				main: '#000000',
			},
			gray: {
				main: '#A9A9A9',
			},
		},
		typography: {
			h3: {
				fontWeight: 900,
				fontSize: '1.3rem',
			},
			h4: {
				fontWeight: 700,
				fontSize: '1.1rem',
			},
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<SnackbarProvider
					//maxSnack={3}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
				>
					<AppProvider>
						<AuthProvider>
							<Routes />
						</AuthProvider>
					</AppProvider>
				</SnackbarProvider>
			</BrowserRouter>
		</ThemeProvider>
	);
}
