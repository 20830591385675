import { Box, Grid, Stack } from '@mui/material';
import Card from './components/Card';
import LoadingContainer from '../../../components/LoadingContent';
import { useFetch } from '../../../hooks/useFetch';
import { Trophy, Star, ShieldCheck, FlagCheckered, UserCirclePlus, UsersThree, SmileyWink } from 'phosphor-react';

const sizeIcon = 50;
export default () => {
	const { data, error, mutate, isFetching } = useFetch('/dashboard');

	if (isFetching) return <LoadingContainer />;

	return (
		<Box>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Stack spacing={2} mb={2} alignItems="stretch" direction={{ xs: 'column', md: 'row' }}>
						<Card name="Novos clientes" icon={<UserCirclePlus size={sizeIcon} />} price={data?.newCustomers} />
						<Card name="Total de clientes" icon={<UsersThree size={sizeIcon} />} price={data?.totalCustomers} />
						<Card name="Pontos concedidos" icon={<ShieldCheck size={sizeIcon} />} price={data?.totalPoints} />
						<Card name="Pontos resgatados" icon={<Trophy size={sizeIcon} />} price={data?.totalRescued} />
					</Stack>
					<Stack spacing={2} mb={2} alignItems="stretch" direction={{ xs: 'column', md: 'row' }}>
						<Card name="Avaliações recebidas" icon={<FlagCheckered size={sizeIcon} />} price={data?.numRatings} />
						<Card name="Media de avaliação" icon={<Star size={sizeIcon} />} price={data?.ratingAverage} />
						<Card name="Satisfação do Cliente" icon={<SmileyWink size={sizeIcon} />} price={data?.csat} />
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
};
