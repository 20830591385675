import React from 'react';
import { useApp } from '../../../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { Button, Chip, Rating } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { blue, red } from '@mui/material/colors';

const EditButton = ({ params }) => {
	const { setQuestion } = useApp();

	return (
		<>
			<Button
				variant="outlined"
				onClick={() => {
					setQuestion({ ...params.row });
				}}
			>
				Editar
			</Button>
		</>
	);
};

const columns = [
	{
		field: 'id',
		headerName: 'ID',
		width: 60,
	},
	{
		field: 'statement',
		headerName: 'Questão',
		minWidth: 250,
		flex: 1,
	},
	{
		field: 'status',
		headerName: 'status',
		minWidth: 250,
		flex: 1,
		renderCell: params => {
			return <Chip variant="outlined" {...getChipProps(params)} />;
		},
	},
	{
		field: 'Ratings.medium_rate',
		headerName: 'Nota média',
		minWidth: 250,
		flex: 1,
		renderCell: ({value}) => {
			return Boolean(value)? <Rating value={Number(value).toFixed(2)} />: 'Nenhuma avalição recebida'
		},
	},
	{
		field: 'actions',
		type: 'actions',
		headerName: 'Editar',
		width: 80,
		renderCell: params => <EditButton params={params} />,
	},
];
function getChipProps(params) {
	if (params.value) {
		return {
			icon: <CheckCircleOutlineIcon />,
			label: 'Ativa',
			style: {
				borderColor: blue[500],
			},
		};
	} else {
		return {
			icon: <HighlightOffIcon />,
			label: 'Inativa',
			style: {
				borderColor: red[500],
			},
		};
	}
}
export default ({ data, isFetching, pageSize, page, setPage, setPageSize }) => {
	return (
		<DataGrid
			autoHeight
			columns={columns}
			loading={isFetching}
			pageSize={pageSize}
			onPageChange={newPage => setPage(newPage)}
			onPageSizeChange={newPageSize => setPageSize(newPageSize)}
			rows={data?.rows || []}
			rowsPerPageOptions={[10, 25, 100]}
			disableSelectionOnClick
			rowCount={data?.pagination.totalRows}
		/>
	);
};
