import { Paper, Button, Typography, Stack, ButtonGroup } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Filter from '../components/Filter';
import CleanFilter from '@mui/icons-material/Delete';
import PageHead from '../../../../components/PageHead';
import UsersList from '../components/UsersList';
import { useFetch } from '../../../../hooks/useFetch';

export default () => {
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [filter, setFilter] = useState(false);
	const [params, setParams] = useState({});

	const navigation = useNavigate();

	const { data, error, mutate, isFetching } = useFetch('/users', {
		params: {
			limit: pageSize,
			page: page,
			paged: true,
			team: 'User',
			...params,
		},
	});

	const handleParams = e => {
		setParams(e);
		setPage(0);
	};

	return (
		<Paper elevation={3} sx={{ borderRadius: '6px', padding: '2rem' }}>
			<Filter filter={filter} setFilter={setFilter} setParams={handleParams} />
			<PageHead pageTitle="Colaboradores">
				<ButtonGroup variant="contained" aria-label="outlined primary button group">
					<Button onClick={() => setFilter(!filter)}>Filtros</Button>
					{Boolean(Object.keys(params).length) && (
						<Button
							onClick={() => {
								handleParams({});
							}}>
							<CleanFilter />
						</Button>
					)}
				</ButtonGroup>

				<Button variant="contained" onClick={e => navigation('/users/new')}>
					Novo
				</Button>
			</PageHead>
			<UsersList
				data={data}
				page={page}
				setPage={setPage}
				pageSize={pageSize}
				setPageSize={setPageSize}
				isFetching={isFetching}
			/>
		</Paper>
	);
};
