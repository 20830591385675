import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import api, { baseURL } from '../services/api';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext({});

export const AuthProvider = props => {
	const [token, setToken] = useState();
	const [user, setUser] = useState({});
	const { enqueueSnackbar } = useSnackbar();

	const [serviceDetailDialog, setServiceDetailDialog] = useState();

	const navigation = useNavigate();

	const verifyProfile = async () => {
		try {
			const response = await api.get('profile');

			sessionStorage.setItem('user', JSON.stringify(response.data.user));
			setUser(response.data.user);
		} catch (error) {
			LogOut();
		}
	};

	const verifySession = async () => {
		const token = sessionStorage.getItem('token');

		if (token) {
			const sessionUser = sessionStorage.getItem('user');
			const user = JSON.parse(sessionUser);
			api.defaults.headers['Authorization'] = `Bearer ${token}`;
			setToken(token);
			setUser(JSON.parse(sessionUser));
		} else {
			delete api.defaults.headers.Authorization;
			setToken();
		}
	};

	useEffect(() => {
		verifySession();
	}, []);

	const LogOut = () => {
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('user');
		verifySession();
		navigation('/');
	};

	return (
		<AuthContext.Provider
			value={{
				logged: Boolean(token),
				user,
				LogOut,
				verifySession,
				verifyProfile,
				setUser,
				serviceDetailDialog,
				setServiceDetailDialog,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) throw 'Use Auth Context inside Auth Provider';

	return context;
};
