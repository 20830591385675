import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import logo from '../../assets/logo.png';
import UserIcon from '@mui/icons-material/PersonRounded';
import Typography from '@mui/material/Typography';

import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Grid, Link } from '@mui/material';
import { House, Storefront, CircleWavyQuestion, User, Gear, UsersThree, Sparkle, Trophy, Star } from 'phosphor-react';
import { useAuth } from '../../contexts/AuthContext';

const drawerWidth = 240;

export default ({ children, page, window }) => {
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const navigation = useNavigate();

	const { LogOut } = useAuth();

	const drawer = (
		<div>
			{/* <Typography sx={{ fontWeight: '700', fontSize: '1.5rem', p: 3 }}>Churrascaria Brasília</Typography> */}
			<Box p={3}>
				<img src={logo} width={180} height={80} />
			</Box>
			<List>
				<ListItem disablePadding>
					<ListItemButton onClick={() => navigation('/backoffice')}>
						<ListItemIcon>
							<House size={24} color="black" />
						</ListItemIcon>
						<ListItemText primary="Home" />
					</ListItemButton>
				</ListItem>

				<ListItem disablePadding>
					<ListItemButton onClick={() => navigation('/customers')}>
						<ListItemIcon>
							<UsersThree size={24} color="black" />
						</ListItemIcon>
						<ListItemText primary="Clientes" />
					</ListItemButton>
				</ListItem>

				<ListItem disablePadding>
					<ListItemButton onClick={() => navigation('/add-points')}>
						<ListItemIcon>
							<Sparkle size={24} color="black" />
						</ListItemIcon>
						<ListItemText primary="Pontuação" />
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton onClick={() => navigation('/rescue-points')}>
						<ListItemIcon>
							<Trophy size={24} color="black" />
						</ListItemIcon>
						<ListItemText primary="Resgatar" />
					</ListItemButton>
				</ListItem>

				<ListItem disablePadding>
					<ListItemButton onClick={() => navigation('/questions')}>
						<ListItemIcon>
							<CircleWavyQuestion size={24} color="black" />
						</ListItemIcon>
						<ListItemText primary="Perguntas" />
					</ListItemButton>
				</ListItem>

				<ListItem disablePadding>
					<ListItemButton onClick={() => navigation('/ratings')}>
						<ListItemIcon>
							<Star size={24} color="black" />
						</ListItemIcon>
						<ListItemText primary="Avaliações" />
					</ListItemButton>
				</ListItem>

				<ListItem disablePadding>
					<ListItemButton onClick={() => navigation('/settings')}>
						<ListItemIcon>
							<Gear size={24} color="black" />
						</ListItemIcon>
						<ListItemText primary="Configurações" />
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton onClick={() => navigation('/users')}>
						<ListItemIcon>
							<User size={24} color="black" />
						</ListItemIcon>
						<ListItemText primary="Colaboradores" />
					</ListItemButton>
				</ListItem>

				<ListItem disablePadding>
					<ListItemButton onClick={LogOut}>
						<ListItemIcon>
							<LogoutIcon color="black" />
						</ListItemIcon>
						<ListItemText primary="Sair" />
					</ListItemButton>
				</ListItem>
			</List>
		</div>
	);

	const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<>
			<Grid container sx={{ flexGrow: 1, height: '100%' }}>
				{/* <CssBaseline /> */}
				<AppBar
					position="fixed"
					sx={{
						width: { sm: `calc(100% - ${drawerWidth}px)` },
						ml: { sm: `${drawerWidth}px` },
					}}>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{ mr: 2, display: { sm: 'none' } }}>
							<MenuIcon />
						</IconButton>

						<Grid container direction="row" alignItems="center" justifyContent="flex-end">
							<Typography>Backoffice Adm</Typography>
							<IconButton edge="end">
								<UserIcon color="secondary" />
							</IconButton>
						</Grid>
					</Toolbar>
				</AppBar>

				<Box
					component="nav"
					sx={{
						flexGrow: 1,
						width: { xs: drawerWidth },
						flexShrink: { sm: 0 },
					}}
					aria-label="mailbox folders">
					<Drawer
						container={container}
						variant="temporary"
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
						sx={{
							display: { xs: 'block', sm: 'none' },
							'& .MuiDrawer-paper': {
								boxSizing: 'border-box',
								width: drawerWidth,
							},
						}}>
						{drawer}
					</Drawer>
					<Drawer
						variant="permanent"
						sx={{
							display: { xs: 'none', sm: 'block' },
							'& .MuiDrawer-paper': {
								boxSizing: 'border-box',
								width: drawerWidth,
							},
						}}
						open>
						{drawer}
					</Drawer>
				</Box>

				<Box
					component="main"
					sx={{
						position: 'relative',
						flexGrow: 1,
						p: 3,
						width: { sm: `calc(100% - ${drawerWidth}px)` },
					}}>
					<Toolbar />

					<Grid container sx={{ minHeight: `calc(100% - 70px)` }} direction="row" spacing={3}>
						<Grid item xs>
							{children}
						</Grid>
						{/* <Grid item xs={3}>
                                <SupportAsside />
                            </Grid> */}
					</Grid>
				</Box>
			</Grid>
		</>
	);
};
