import { Grid, Stack } from '@mui/material';
import { Cards } from './components/Cards';
import { useFetch } from '../../../hooks/useFetch';
import LoadingContainer from '../../../components/LoadingContent';

export default () => {
	const { data, error, mutate, isFetching } = useFetch('/customer/dash');

	if (isFetching) return <LoadingContainer />;
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Stack spacing={2} mb={2} alignItems="stretch" direction="row">
					<Cards name="Pontos válidos" price={data?.validPoints} />
					<Cards name="Expirados" price={data?.expired} />
				</Stack>
				<Stack spacing={2} mb={2} alignItems="stretch" direction="row">
					<Cards name="Resgates" price={data?.allRescued} />
					<Cards name="Expiram hoje" price={data?.toExpire} />
				</Stack>
			</Grid>
		</Grid>
	);
};
