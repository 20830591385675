import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React from 'react';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import GlobalFilter from '../../../../components/GlobalFilter';

export default function Filter({ filter, setFilter, setParams, setFiltered }) {
	const { handleChange, handleBlur, handleReset, handleSubmit, values, errors, isSubmitting, setFieldValue, setValues } =
		useFormik({
			initialValues: {
				name: '',
				status: 'All',
				categories: [],
			},
			onSubmit: async (values, { setSubmitting }) => {
				setParams(values);
				setFiltered('flex');
			},
		});

	function handleToggle() {
		setFilter(!filter);
	}
	return (
		<GlobalFilter handleClose={handleToggle} title="Filter de ofertas" open={filter}>
			<Grid container spacing={5}>
				<Grid item xs={12}>
					<TextField fullWidth name="name" label="Buscar por nome" onChange={handleChange} />
				</Grid>
				<Grid item xs={12}>
					<Typography>Status</Typography>
					<FormControl fullWidth>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							defaultValue="Todos"
							name="status"
							onChange={handleChange}>
							<FormControlLabel value="All" control={<Radio />} label="Todos" />
							<FormControlLabel value="Ativos" control={<Radio />} label="Ativos" />
							<FormControlLabel value="Inativos" control={<Radio />} label="Inativos" />
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item xs={12} direction="row" justifyContent="center">
					<LoadingButton
						fullWidth
						loading={isSubmitting}
						onClick={() => {
							handleSubmit();
						}}
						variant="contained">
						Aplicar filtro
					</LoadingButton>
				</Grid>
			</Grid>
		</GlobalFilter>
	);
}
