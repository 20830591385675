import { Card, CardActions, CardContent, Typography, TextField, Stack, IconButton } from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import api from '../../../services/api';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { validaCPF } from '../../../services/validDoc';
import axios from 'axios';
import Search from '@mui/icons-material/TravelExplore';

export function RegisterCustomer() {
	const validateLogin = Yup.object().shape({
		document: Yup.string()
			.length(11, 'CPF incompleto')
			.test('invalid-cpf', 'CPF inválido', cpf => validaCPF(cpf))
			.required('Campo obrigatório'),
		name: Yup.string().required('Campo obrigatório'),
		birthday: Yup.date().required('Campo obrigatório'),
		email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
		street: Yup.string().required('Campo obrigatório'),
		complement: Yup.string().required('Campo obrigatório'),
		number: Yup.string().required('Campo obrigatório'),
		neighborhood: Yup.string().required('Campo obrigatório'),
		state: Yup.string().required('Campo obrigatório'),
		city: Yup.string().required('Campo obrigatório'),
		password: Yup.string().min(6, 'Mínimo de 6 caracteres').required('Campo obrigatório'),
		repeat_password: Yup.string()
			.oneOf([Yup.ref('password'), null], 'As senhas não conferem')
			.required('Campo obrigatório'),
	});

	const { enqueueSnackbar } = useSnackbar();

	const navigation = useNavigate();
	const [error, setError] = useState();

	useHandleErrors(error);

	const { handleChange, handleSubmit, handleReset, values, errors, isSubmitting, setValues, setFieldValue, setFieldError } =
		useFormik({
			initialValues: {
				document: '',
				name: '',
				email: '',
				zipcode: '',
				street: '',
				complement: '',
				neighborhood: '',
				state: '',
				city: '',
				password: '',
				birthday:'',
			},
			validationSchema: validateLogin,
			onSubmit: async (values, { setSubmitting, resetForm }) => {
				try {
					await api.post('auth/register', values);
					enqueueSnackbar('Cadastro realizado com sucesso', { variant: 'success' });
					handleReset();
					navigation('/login');
				} catch (error) {
					setError(error);
				}
			},
		});

	const [searchingZipcode, setSearchZipcode] = useState(false);
	const getAddress = async zipcode => {
		try {
			setFieldValue('zipcode', zipcode);

			if (zipcode.length === 8) {
				setSearchZipcode(true);
				const { data } = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);
				setValues({
					...values,
					street: data.logradouro,
					neighborhood: data.bairro,
					city: data.localidade,
					state: data.uf,
					zipcode,
				});
				setSearchZipcode(false);
			}
		} catch (error) {
			setFieldError('zipcode', 'CEP não encontrado');
			console.log('Error', error);
		}
	};

	return (
		<Stack sx={{ justifyContent: 'center', alignItems: 'center', my: 5 }}>
			<Card
				elevation={3}
				sx={{
					borderRadius: '6px',
					width: '22rem',
					maxWidth: '23rem',
					padding: '0.7rem',
				}}>
				<CardContent>
					<Typography sx={{ fontSize: '2rem', fontWeight: '700' }} color="primary">
						Faça seu cadastro
					</Typography>
					<Typography sx={{ fontSize: '0.9rem', fontWeight: '700' }} color="#A0AEC0">
						Acesso do cliente
					</Typography>
					

					<Stack direction="column" spacing={3} sx={{ mt: 4 }}>
						<TextField
							label="CPF"
							variant="outlined"
							id="document"
							name="document"
							onChange={handleChange}
							value={values.document}
							error={errors.document}
							helperText={errors.document}
						/>

						<TextField
							label="Nome completo"
							variant="outlined"
							id="name"
							name="name"
							onChange={handleChange}
							value={values.name}
							error={errors.name}
							helperText={errors.name}
						/>
						<TextField
							label="Data de nascimento"
							variant="outlined"
							type="date"
							id="birthday"
							name="birthday"
							onChange={handleChange}
							value={values.birthday}
							error={errors.birthday}
							helperText={errors.birthday}
						/>

						<TextField
							label="E-mail"
							variant="outlined"
							id="email"
							name="email"
							type="email"
							onChange={handleChange}
							value={values.email}
							error={errors.email}
							helperText={errors.email}
						/>

						<TextField
							label="CEP"
							variant="outlined"
							id="zipcode"
							name="zipcode"
							onChange={e => getAddress(e.target.value)}
							value={values.zipcode}
							error={errors.zipcode}
							helperText={errors.zipcode}
						/>
						<TextField
							label="Rua"
							variant="outlined"
							id="street"
							name="street"
							onChange={handleChange}
							value={values.street}
							error={errors.street}
							helperText={errors.street}
						/>

						<TextField
							label="Complemento"
							variant="outlined"
							id="complement"
							name="complement"
							onChange={handleChange}
							value={values.complement}
							error={errors.complement}
							helperText={errors.complement}
						/>
						<TextField
							label="Numero"
							variant="outlined"
							id="number"
							name="number"
							onChange={handleChange}
							value={values.number}
							error={errors.number}
							helperText={errors.number}
						/>
						<TextField
							label="Bairro"
							variant="outlined"
							id="neighborhood"
							name="neighborhood"
							onChange={handleChange}
							value={values.neighborhood}
							error={errors.neighborhood}
							helperText={errors.neighborhood}
						/>

						<TextField
							label="UF"
							variant="outlined"
							id="state"
							name="state"
							onChange={handleChange}
							value={values.state}
							error={errors.state}
							helperText={errors.state}
						/>

						<TextField
							label="Cidade"
							variant="outlined"
							id="city"
							name="city"
							onChange={handleChange}
							value={values.city}
							error={errors.city}
							helperText={errors.city}
						/>

						<TextField
							label="Senha"
							variant="outlined"
							id="password"
							name="password"
							type="password"
							onChange={handleChange}
							value={values.password}
							error={errors.password}
							helperText={errors.password}
						/>
						<TextField
							label="Confirme a senha"
							variant="outlined"
							id="repeat_password"
							name="repeat_password"
							type="password"
							onChange={handleChange}
							value={values.repeat_password}
							error={errors.repeat_password}
							helperText={errors.repeat_password}
						/>
					</Stack>

					{/* <FormGroup>
						<FormControlLabel
							control={<Switch onChange={e => rememberMe(e.target.checked)} checked={checked} />}
							label="Lembrar acesso"
						/>
					</FormGroup> */}
				</CardContent>
				<CardActions>
					<LoadingButton
						onClick={handleSubmit}
						fullWidth
						sx={{ color: '#FAFAFA' }}
						disabled={isSubmitting || Boolean(Object.keys(errors).length) || !Boolean(Object.keys(values).length)}
						loading={isSubmitting}
						variant="contained">
						Entrar
					</LoadingButton>
				</CardActions>
			</Card>
		</Stack>
	);
}
