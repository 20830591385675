import { Rating } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
	// {
	// 	field: 'id',
	// 	headerName: 'ID',
	// 	width: 70,
	// },
	{
		field: 'message',
		headerName: 'Sugestão do cliente',
		flex: 1,
	},
	{
		field: 'createdAt',
		headerName: 'Data',
		width: 200,
		valueGetter: ({ value }) => new Date(value).toLocaleString(),
	},
	{
		field: 'rating_value',
		headerName: 'Nota',
		width: 150,
		renderCell: ({ value }) => {
			return <Rating value={value} />;
		},
	},
];

export default ({ data, page, setPage, setPageSize, pageSize, isFetching }) => {
	return (
		<DataGrid
			autoHeight
			columns={columns}
			//paginationMode="server"
			loading={isFetching}
			pageSize={pageSize}
			onPageChange={newPage => setPage(newPage)}
			onPageSizeChange={newPageSize => setPageSize(newPageSize)}
			rows={data?.rows || []}
			rowsPerPageOptions={[10, 25, 100]}
			disableSelectionOnClick
			rowCount={data?.pagination.totalRows}
		/>
	);
};
