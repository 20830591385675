import AddPoints from './components/AddPoints';
 
export default () => {
	 
	return (
		<>
			<AddPoints />
		</>
	);
};
