import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import PageHead from '../../../components/PageHead';
import { useFetch } from '../../../hooks/useFetch';
import { isPast, parseISO } from 'date-fns';
import Verified from '@mui/icons-material/Verified';
import HighlightOff from '@mui/icons-material/HighlightOff';
import LoadingContainer, { NothingContent } from '../../../components/LoadingContent';

export default props => {
	const { data, error, isFetching, mutate } = useFetch('/rescuded');
	if (isFetching) return <LoadingContainer />;

	return (
		<Paper elevation={3} sx={{ borderRadius: '6px', padding: '1.5rem' }}>
			<PageHead pageTitle="Resgates realizados" />
			<Stack spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
				{!Boolean(data.length) && <NothingContent label="Nenhum resgate realizado" />}
				{data?.map(group => (
					<Box key={group[0]}>
						<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
							<Box>
								<Typography>Dia {new Date(group[0]).toLocaleDateString()}</Typography>
								<Typography>
									{group[1].length} ponto{Boolean(group[1].length) && 's'} resgatado
									{Boolean(group[1].length) && 's'}
								</Typography>
							</Box>
							<Box>
								<Verified color="green" />
							</Box>
						</Stack>
					</Box>
				))}
			</Stack>
		</Paper>
	);
};
