import { Typography, Stack, Grid } from '@mui/material';

export default function PageHead({ children, pageTitle }){
	return (
		<Grid
			container
			direction={{ xs: 'column', md: 'row' }}
			justifyContent="space-between"
			columnGap={30}
			rowGap={3}
			sx={{ paddingBottom: 3 }}
		>
			<Grid item>
				<Typography variant="h3">{pageTitle}</Typography>
			</Grid>
			<Grid item>
				<Stack
					direction={{ xs: 'column', md: 'row' }}
					rowGap={2}
					columnGap={2}
					alignItems={{ xs: 'flex-start', md: 'center' }}
				>
					{children}
				</Stack>
			</Grid>
		</Grid>
	);
};
