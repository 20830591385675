import React, { useState, useEffect } from 'react';
import {
	TextField,
	Grid,
	Typography,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	FormControlLabel,
	Checkbox,
	Button,
	Box,
	FormHelperText,
	FormGroup,
	Switch,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import api from '../../../../services/api';
import useHandleErrors from '../../../../hooks/useHandleErrors';
import PageHead from '../../../../components/PageHead';

export function UsersForm({ data }) {
	const { enqueueSnackbar } = useSnackbar();

	const navigation = useNavigate();

	const productScheema = Yup.object().shape({
		name: Yup.string().required('Campo Obrigatório.'),
		document: Yup.string().required('Campo Obrigatório'),
		password: Yup.string(),
		status: Yup.boolean(),
	});

	const { handleChange, handleBlur, handleReset, handleSubmit, values, errors, isSubmitting, setFieldValue, setValues } =
		useFormik({
			initialValues: {
				name: '',
				document: '',
				password: '',
				status: true,
			},
			validationSchema: productScheema,
			onSubmit: async (values, { setSubmitting }) => {
				try {
					setSubmitting(true);
					if (values.id) {
						console.log(values)
						await api.put(`/users/${data.id}`, values);
						enqueueSnackbar('Usuário alterado', { variant: 'success' });
					} else {
						const response = await api.post('/users', values);
						enqueueSnackbar('Usuario inserido', { variant: 'success' });
						navigation(`/users/${response.data.data.id}`);
					}
					setSubmitting(false);
				} catch (error) {
					setError(error);
				}
			},
		});
	useEffect(() => {
		if (data) {
			setValues({ ...data }, false);
		}
	}, [data]);
	const [error, setError] = useState();
	useHandleErrors(error);

	const [preview, setPreview] = useState();

	const handleImage = event => {
		const image = event.target.files[0];
		setPreview(URL.createObjectURL(image));

		const reader = new FileReader();
		reader.readAsDataURL(image);
		reader.onload = e => {
			setFieldValue('image', reader.result.replace(/^data:image\/[a-z]+;base64,/, ''));
		};
		reader.onerror = e => console.log(e);
	};

	return (
		<Paper elevation={3} sx={{ borderRadius: '6px', padding: '2rem' }}>
			<PageHead pageTitle="Criar ou editar colaborador" />

			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						name="name"
						error={Boolean(errors)}
						helperText={errors.name}
						onChange={handleChange}
						value={values.name}
						label="Nome completo"
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						name="document"
						error={Boolean(errors)}
						helperText={errors.document}
						onChange={handleChange}
						value={values.document}
						label="Documento/Login"
						variant="outlined"
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<TextField
						fullWidth
						type="password"
						error={Boolean(errors.password)}
						helperText={errors.password}
						onChange={handleChange}
						label="Senha"
						variant="outlined"
						name="password"
						value={values.password}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormGroup>
						<FormControlLabel
							control={<Switch disabled={values.team === 'adm'} checked={values.status} onChange={e=>setFieldValue('status', e.target.checked)} />}
							label={values.status ? 'Ativo' : 'Inativo'}
						/>
					</FormGroup>
				</Grid>
			</Grid>
			<Stack direction="row" justifyContent="flex-end">
				<LoadingButton
					loading={isSubmitting}
					onClick={() => {
						console.log(errors);
						handleSubmit();
					}}
					size="large"
					variant="contained">
					Salvar dados
				</LoadingButton>
			</Stack>
		</Paper>
	);
}
