import { Button, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

const ViewDetailsButton = ({ params, id }) => {
	const navigation = useNavigate();

	return (
		<Button
			variant="outlined"
			onClick={() => navigation(`/users/${id}`)}
			size="small"
			style={{ marginLeft: 16, fontSize: 12 }}
			tabIndex={params.hasFocus ? 0 : -1}>
			Detalhes
		</Button>
	);
};

const columns = [
	{
		field: 'id',
		headerName: 'ID',
		width: 70,
	},
	{
		field: 'name',
		headerName: 'Nome do colaborador',
		flex:1
	},
	{
		field: 'status',
		headerName: 'Status',
		width: 150,
		renderCell: params => {
			return <Chip label={params.row.status ? 'Ativo' : 'Inativo'} color={params.row.status ? 'success' : 'warning'} />;
		},
	},
	{
		field: 'actions',
		type: 'actions',
		width: 150,
		renderCell: params => <ViewDetailsButton params={params} id={params.row.id} />,
	},
];

export default ({ data, setPage, setPageSize, pageSize, isFetching }) => {
	return (
		<DataGrid
			autoHeight
			columns={columns}
			paginationMode="server"
			loading={isFetching}
			pageSize={pageSize}
			onPageChange={newPage => setPage(newPage)}
			onPageSizeChange={newPageSize => setPageSize(newPageSize)}
			rows={data?.rows || []}
			rowsPerPageOptions={[10, 25, 100]}
			disableSelectionOnClick
			rowCount={data?.pagination.totalRows}
		/>
	);
};
