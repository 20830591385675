import { Paper, Button, Typography, Stack, ButtonGroup } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFetch';
import Filter from './components/Filter';
import CleanFilter from '@mui/icons-material/Delete';
import RatingsList from './components/RatingsList';
import PageHead from '../../../components/PageHead';
import { useSnackbar } from 'notistack';
import api from '../../../services/api';
export default () => {
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [filter, setFilter] = useState(false);
	const [params, setParams] = useState({});
	const [filtered, setfiltered] = useState('none');

	const { enqueueSnackbar } = useSnackbar();

	const { data, error, mutate, isFetching } = useFetch('/ratings', {
		params: {
			limit: pageSize,
			paged: true,
			page: page,
			...params,
		},
	});

	const handleParams = e => {
		setParams(e);
		setPage(0);
	};

	return (
		<Paper elevation={3} sx={{ borderRadius: '6px', padding: '2rem' }}>
			<Filter filter={filter} setFilter={setFilter} setParams={handleParams} setFiltered={setfiltered} />
			<PageHead pageTitle="Avaliações">
				<ButtonGroup variant="contained" aria-label="outlined primary button group">
					<Button onClick={() => setFilter(!filter)}>Filtros</Button>
					{Boolean(Object.keys(params).length) && (
						<Button
							onClick={() => {
								handleParams({});
							}}>
							<CleanFilter />
						</Button>
					)}
				</ButtonGroup>
			</PageHead>
			<RatingsList
				data={data}
				page={page}
				setPage={setPage}
				pageSize={pageSize}
				setPageSize={setPageSize}
				isFetching={isFetching}
			/>
		</Paper>
	);
};
