import { Outlet } from 'react-router-dom';
import { NoAuthorized } from '../../components/ProtectedContainer';
import { useAuth } from '../../contexts/AuthContext';
import DashboardContainer from './DashboardContainer';

export default function ProtectedDashboard() {
	const { user } = useAuth();

	if (user.team === 'cus') {
		return (
			<DashboardContainer>
				<Outlet />
			</DashboardContainer>
		);
	} else {
		return <NoAuthorized />;
	}
}
