import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React from 'react';
import { useFormik } from 'formik';

import { LoadingButton } from '@mui/lab';
import GlobalFilter from '../../../../components/GlobalFilter';

export default ({ filter, setFilter, setParams }) => {
	const { handleChange, handleSubmit, values, isSubmitting } = useFormik({
		initialValues: {
			name: '',
			status: '',
		},
		onSubmit: async (values, { setSubmitting }) => {
			setParams(values);
			setFilter(false);
		},
	});

	function handleToggle() {
		setFilter(false);
	}
	return (
		<GlobalFilter title="Filtro de categorias" handleClose={handleToggle} open={filter}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						fullWidth
						name="name"
						value={values.name}
						label={'Por enunciado'}
						sx={{ borderRadius: '6px' }}
						onChange={handleChange}
						//placeholder={'Email Ex: exemple@gmail.com'}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography>Status</Typography>
					<FormControl>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							value={values.status}
							name="status"
							onChange={handleChange}
						>
							<FormControlLabel value="" control={<Radio />} label="Todos" />
							<FormControlLabel value={true} control={<Radio />} label="Ativas" />
							<FormControlLabel value={false} control={<Radio />} label="Inativas" />
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<LoadingButton
						loading={isSubmitting}
						onClick={() => {
							handleSubmit();
						}}
						fullWidth
						variant="contained"
					>
						Ver Resultados
					</LoadingButton>
				</Grid>
			</Grid>
		</GlobalFilter>
	);
};
