import { useFetch } from '../../../hooks/useFetch';
import EditSettings from './screens/EditSettings';

export default () => {
	const { data, error, mutate, isFetching } = useFetch(`/settings`);

	console.log(data) 
	return (
		<>
			<EditSettings data={data} isFetching={isFetching} mutate={mutate} />
		</>
	);
};
