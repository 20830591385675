import { Box, Stack, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ProtectedLogin } from './ProtectedLogin';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
export default function ProtectedContainer() {
	const { logged } = useAuth();

	if (!logged) return <ProtectedLogin />;

	return <Outlet />;
}

export function NoAuthorized() {
	return (
		<Box width="100%" height="100%" paddingY={25}>
			<Stack justifyContent="center"  width="100%" height="100%" alignItems="center" direction="column">
				<ErrorOutline sx={{ fontSize: 80 }} />
				<Typography>Acesso não autorizado</Typography>
			</Stack>
		</Box>
	);
}
