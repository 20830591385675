import React, { useState } from 'react';
import { TextField, Grid, FormHelperText } from '@mui/material';
import Paper from '@mui/material/Paper';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import useHandleErrors from '../../../../hooks/useHandleErrors';
import { useFormik } from 'formik';
import api from '../../../../services/api';
import { useSnackbar } from 'notistack';
import PageHead from '../../../../components/PageHead';
import { validaCPF } from '../../../../services/validDoc';

export default () => {
	const { enqueueSnackbar } = useSnackbar();

	const ContainerOrdersScheema = Yup.object().shape({
		document: Yup.string()
			.required('Campo obrigatório')
			.length(11, 'CPF incompleto')
			.test('invalid-cpf', 'CPF inválido', cpf => validaCPF(cpf))
			.required('Campo obrigatório'),
	});

	const [error, setError] = useState();
	useHandleErrors(error);

	const { handleChange, handleSubmit, handleReset, values, errors, isSubmitting, setFieldValue, setValues } = useFormik({
		initialValues: {
			document: '',
		},
		validationSchema: ContainerOrdersScheema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true);
				await api.post(`/score/`, { customer_document: values.document });
				enqueueSnackbar('Usuário pontuado', { variant: 'success' });

				handleReset();
				setSubmitting(false);
			} catch (error) {
				setError(error);
			}
		},
	});

	return (
		<>
			<Paper elevation={3} sx={{ borderRadius: '6px', padding: '2rem', mb: '2rem' }}>
				<PageHead pageTitle="Adicionar pontuação" />

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name="document"
							value={values.document}
							onChange={handleChange}
							error={Boolean(errors.document)}
							helperText={errors.document}
							fullWidth
							label="CPF"
							variant="outlined"
							maxLength={11}
						/>
						<FormHelperText>
							Inserindo um novo CPF, o cliente será cadastrado e automaticamente pontuado.
						</FormHelperText>
					</Grid>

					<Grid item xs={12} md={12}>
						<LoadingButton
							disabled={!values.document || isSubmitting}
							onClick={() => window.confirm(`Confirme o CPF do cliente ${values.document}`) && handleSubmit()}
							loading={isSubmitting}
							type="submit"
							variant="contained">
							Adicionar
						</LoadingButton>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
};
