import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import Dashboard from '@mui/icons-material/Dashboard';
import UserIcon from '@mui/icons-material/PersonRounded';
import Typography from '@mui/material/Typography';

import { useNavigate } from 'react-router-dom';
import { Button, Grid, Paper } from '@mui/material';
import { Trophy } from 'phosphor-react';
import { useAuth } from '../../contexts/AuthContext';
import logo from '../../assets/logo_branco.png';

export default ({ children, page, window }) => {
	const navigation = useNavigate();

	const { LogOut, user } = useAuth();

	return (
		<>
			<Grid container sx={{ height: '100%' }}>
				<AppBar position="fixed">
					<Toolbar>
						<img src={logo} width={120} height={30} />
						<Box sx={{ flexGrow: 1,ml:3 ,display: { xs: 'none', md: 'flex' } }}>
							<Button onClick={() => navigation('/dashboard')} sx={{ my: 2, color: 'white', display: 'block' }}>
								Dashboard
							</Button>
							<Button onClick={() => navigation('/profile')} sx={{ my: 2, color: 'white', display: 'block' }}>
								Perfil
							</Button>
							<Button onClick={() => navigation('/rescuded')} sx={{ my: 2, color: 'white', display: 'block' }}>
								Resgates
							</Button>
							<Button onClick={() => navigation('/history')} sx={{ my: 2, color: 'white', display: 'block' }}>
								Histórico
							</Button>
						</Box>
						<Grid container direction="row" alignItems="center" justifyContent="flex-end">
							<Typography sx={{ mr: 5 }}>{user?.name.split(' ',1)[0]}</Typography>
							<Button sx={{ color: 'white' }} onClick={() => LogOut()}>
								Sair
							</Button>
						</Grid>
					</Toolbar>
				</AppBar>

				<Box
					//component="main"
					sx={{
						marginX: 'auto',
						width: '100%',
						maxWidth: 900,
						p: 3,
					}}>
					<Toolbar />
					<Grid
						container
						sx={{ minHeight: `calc(100% - 70px)` }}
						spacing={3}>
						<Grid item xs>
							{children}
						</Grid>
					</Grid>
				</Box>

				<Paper
					sx={{ display: { xs: 'block', sm: 'none' }, position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 999 }}>
					<BottomNavigation showLabels>
						<BottomNavigationAction
							onClick={e => navigation('/dashboard')}
							label="Meus pontos"
							icon={<Dashboard />}
						/>
						<BottomNavigationAction
							onClick={() => navigation('/rescuded')}
							label="Resgates"
							icon={<Trophy size={25} />}
						/>
						<BottomNavigationAction onClick={() => navigation('/history')} label="Histórico" icon={<RestoreIcon />} />
						<BottomNavigationAction onClick={() => navigation('/profile')} label="Meus dados" icon={<UserIcon />} />
					</BottomNavigation>
				</Paper>
			</Grid>
		</>
	);
};
