import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

export default ({ result, name, icon  }) => {
	return (
		<Card elevation={3} sx={{ borderRadius: '8px', justifyContent: 'center', flexGrow: 4, mb:2 }}>
			<CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				{result?.user && <Typography sx={{ fontSize: {xs: '0.8rem', md: '1rem'}, fontWeight: '700', color: 'black', mb: '0.25rem' }}>{result?.user.name}</Typography>}
				<Box mb={2}>{icon}</Box>
				<Typography sx={{ fontSize: {xs: '0.8rem', md: '1rem'}, fontWeight: '700', color: 'black', mb: '0.25rem' }}>{name}</Typography>
				{/* <Typography sx={{ fontSize: {xs: '0.5rem', md: '.8rem'}, fontWeight: '700', color: 'gray', mb: '0.7rem' }}>Total</Typography> */}
				<Typography sx={{ color: 'black', fontWeight: '700', fontSize: '1.12' }}>{result?.count}</Typography>
			</CardContent>
		</Card>
	);
};
