import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Checkbox, FormControlLabel, ButtonGroup, FormHelperText } from '@mui/material';
import Paper from '@mui/material/Paper';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useHandleErrors from '../../../../hooks/useHandleErrors';
import { useFormik } from 'formik';
import api from '../../../../services/api';
import { useApp } from '../../../../contexts/AppContext';
import { useSnackbar } from 'notistack';
import CleanFilter from '@mui/icons-material/Delete';
import PageHead from '../../../../components/PageHead';

export default ({ data, mutate }) => {
	const { question, setQuestion } = useApp();
	const { enqueueSnackbar } = useSnackbar();
	const [buttonName, setButtonName] = useState('Criar');

	const validationSchema = Yup.object().shape({
		day_for_expire: Yup.string().required('Campo obrigatório'),
		daily_limit: Yup.string().required('Campo obrigatório'),
	});

	const [error, setError] = useState();
	useHandleErrors(error);

	const { handleChange, handleSubmit, values, errors, isSubmitting, setValues } = useFormik({
		initialValues: {
			day_for_expire: '',
			daily_limit: ''
		},
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true);
				await api.put(`/settings`, values);
				enqueueSnackbar('Configuração alterada', { variant: 'success' });
				setSubmitting(false);
				mutate()
			} catch (error) {
				setError(error);
			}
		},
	});
	useEffect(() => {
		if (data) {
			setValues({ ...data }, false);
		}
	}, [data]);
	return (
		<>
			<Paper elevation={3} sx={{ borderRadius: '6px', padding: '2rem', mb: '2rem' }}>
				<PageHead pageTitle="Configurações">Ultima alteração: {new Date(values.updatedAt).toLocaleString()}</PageHead>

				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField
							name="day_for_expire"
							value={values.day_for_expire}
							onChange={handleChange}
							error={Boolean(errors.day_for_expire)}
							helperText={errors.day_for_expire}
							fullWidth
							label="Validade dos pontos (dias)"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							name="daily_limit"
							value={values.daily_limit}
							onChange={handleChange}
							error={Boolean(errors.daily_limit)}
							helperText={errors.daily_limit}
							fullWidth
							label="Limite de pontuação diária"
							variant="outlined"
						/>
						<FormHelperText>Defina o valor 0 para pontuação ilimitada por dia</FormHelperText>
					</Grid>

					<Grid item xs={12} md={12}>
						<LoadingButton onClick={() => handleSubmit()} loading={isSubmitting} type="submit" variant="contained">
							Salvar dados
						</LoadingButton>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
};
